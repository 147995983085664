import constants from '@/constants'
import { StringUtils } from './stringUtils'

export class FormUtils {
  static isNumber(evt) {
    evt = evt || window.event
    var charCode = evt.which ? evt.which : evt.keyCode

    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault()
    }

    return true
  }

  static isNumberAndOnlyOneDot(evt) {
    evt = evt || window.event
    var charCode = evt.which ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault()
    }
    const val = evt.target.value
    const arrVal = val.split('.')
    // only ont dot
    if (charCode === 46 && arrVal.length === 2) {
      evt.preventDefault()
    }

    // max three number after dot
    if (
      arrVal.length === 2 &&
      arrVal[1].toString().length >= constants.maximumAfterDecimal &&
      evt.target.selectionStart === val.length
    ) {
      evt.preventDefault()
    }

    return true
  }

  static isOnPasteOnlyNumber(evt) {
    const val = evt.clipboardData.getData('text')
    if (val === null || val.length === 0) {
      return null
    }

    const isNum = FormUtils.isNumeric(StringUtils.replaceComma(val))
    if (isNum) {
      return val
    }

    return null
  }

  static isOnPasteOnlyNumberAndMaximum(evt, maximun) {
    const val = evt.clipboardData.getData('text')
    if (val === null || val.length === 0) {
      return null
    }

    let newVal = StringUtils.replaceComma(val)
    const isNum = FormUtils.isNumeric(newVal)
    if (isNum && newVal.toString().length <= maximun) {
      if (StringUtils.isExistDecimal(newVal)) {
        const arrVal = newVal.split('.')
        if (
          arrVal.length === 2 &&
          arrVal[1].toString().length >= constants.maximumAfterDecimal
        ) {
          return parseFloat(newVal).toFixed(constants.maximumAfterDecimal).toString()
        }
      }

      return newVal
    }

    if (isNum && newVal.toString().length > maximun) {
      if (StringUtils.isExistDecimal(newVal)) {
        const arrVal = newVal.split('.')
        if (
          arrVal.length === 2 &&
          arrVal[1].toString().length >= constants.maximumAfterDecimal
        ) {
          newVal = parseFloat(newVal).toFixed(constants.maximumAfterDecimal)
          if (newVal.toString().length > maximun) {
            return newVal.toString().substring(0, maximun)
          }

          return newVal.toString()
        }
      }
      return newVal.toString().substring(0, maximun)
    }

    return null
  }

  // decimal
  static isDecimal(evt) {
    evt = evt || window.event
    var charCode = evt.which ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault()
    }

    const val = evt.target.value
    const arrVal = val.split(constants.separation)
    // only ont dot
    if (charCode === 46 && arrVal.length === 2) {
      evt.preventDefault()
    }

    // check enter after dot
    if (
      arrVal.length === 2 &&
      arrVal[1].toString().length >= constants.maximumAfterDecimal &&
      evt.target.selectionStart >= val.length - constants.maximumAfterDecimal
    ) {
      evt.preventDefault()
    }

    return true
  }

  static isOnPasteOnlyDecimal(evt) {
    const val = evt.clipboardData.getData('text')
    if (val == null || val.length === 0) {
      return null
    }

    const newVal = StringUtils.replaceComma(val)
    const isNum = FormUtils.isNumeric(newVal)
    if (isNum) {
      return parseFloat(newVal)
        .toFixed(constants.maximumAfterDecimal)
        .toString()
    }

    return null
  }

  static isNumeric(val) {
    return !isNaN(parseFloat(val)) && isFinite(val)
  }
}
