<template>
  <v-dialog v-model="internalValue" persistent :width="widthDialog">
    <v-card class="pa-2">
      <v-card-text class="text-center">
        {{ message }}
        <v-progress-linear indeterminate color="primary" class="mb-0 mt-2" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import constants from '@/constants'
export default {
  props: {
    widthDialog: {
      type: Number,
      default: constants.widthDialog
    },
    value: Boolean,
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value(val, oldVal) {
      if (val === oldVal) return

      this.internalValue = val
    }
  }
}
</script>
