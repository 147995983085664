<template>
  <v-app>
    <v-layout justify-center align-center class="bg-white">
      <v-layout class="box-noauth bg-main" />
      <v-layout justify-center>
        <v-layout class="w-main h-wrap-auth pos-rel py-8">
          <h2 class="logo pos-abs" />
          <v-layout justify-center align-center>
            <v-layout column class="form login-frm box-shadow px-13 py-16">
              <label
                class="text-uppercase font-weight-medium text-size-title mt-8 text-center"
              >
                {{ $t('forgot_password') }}
              </label>
              <p class="mt-2 mb-10 text-body-2 text-center">
                <span class="text-uppercase primary--text font-weight-large">
                  {{ $t('brand') }}
                </span>
                {{ $t('post') }} {{ $t('ready_to_support_you') }}
              </p>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                  <v-layout column>
                    <label class="text-subtitle-1">
                      {{ $t('phone') }}
                      <span class="red--text">{{ $t('required') }}</span>
                    </label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="phone"
                      :rules="phoneRules"
                    >
                      <v-text-field
                        v-model="phone"
                        outlined
                        dense
                        class="rounded-lg"
                        :maxlength="maximumPhone"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.phone')"
                        @keypress="isNumber($event)"
                      />
                    </validation-provider>
                  </v-layout>
                  <div>
                    <v-btn
                      block
                      large
                      color="primary"
                      type="submit"
                      class="rounded-lg mt-10 mb-8"
                      :disabled="invalid"
                    >
                      <span class="font-weight-medium white--text">
                        {{ $t('continue') }}
                      </span>
                    </v-btn>
                  </div>
                </v-form>
              </validation-observer>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />
  </v-app>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import constants from '@/constants'
import routePaths from '@/router/routePaths'
import { FormUtils } from '@/helpers/formUtils'
import { StringUtils } from '@/helpers/stringUtils'
import { mapActions } from 'vuex'
import { StorageService } from '@/services/storageService'

export default {
  components: { DialogLoading, DialogNotification },
  data: () => ({
    phone: '',
    maximumPhone: constants.maximumPhone,
    phoneRules: {
      required: true,
      min: constants.minimumPhone,
      max: constants.maximumPhone,
      regex: constants.regExp.phone
    },

    // loading
    showLoading: false,

    // notifications
    showNoti: false,
    typeNoti: constants.typeAlert.warning,
    message: ''
  }),
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  methods: {
    ...mapActions('user', ['forgotPassword']),
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid) {
        this.showLoading = true
        const pars = {
          phone: this.phone.trim()
        }
        const { status, data } = await this.forgotPassword(pars)
        this.showLoading = false
        if (status === constants.statusCode.ok && data) {
          StorageService.setUserName(this.phone.trim())
          StorageService.setTimeGetOtp(data.metadata.timestamp)
          this.$router.push(routePaths.OTP)
        } else if (
          status === constants.statusCode.badRequest &&
          data !== null &&
          data.message.startsWith(constants.startsWithOtpOverLimit)
        ) {
          StorageService.setUserName(this.phone.trim())
          this.$router.push({
            path: routePaths.OTP,
            query: { message: data.message }
          })
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: '' }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/form.scss" lang="scss" />
